import React from "react";
import Particle from "../Particle";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaBriefcase } from 'react-icons/fa';
import "./Experience.css"; 

function ExperienceTimeline() {
  return (
    <section className="experience-section">
      <Particle />
      <div className="experience-container">
        <h2 className="section-heading">My Experiences</h2>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgba(255, 255, 255, 0.8)', color: '#000000', borderRadius: '15px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)' }}
            contentArrowStyle={{ borderRight: '7px solid rgba(255, 255, 255, 0.8)' }}
            date="April 2024 - July 2024"
            iconStyle={{ background: 'linear-gradient(135deg, #2f3640, #3e497a)', color: '#ffffff' }}
            icon={<FaBriefcase />}
          >
            <h3 className="vertical-timeline-element-title">Research Assistant</h3>
            <h4 className="vertical-timeline-element-subtitle">University of Illinois Urbana-Champaign</h4>
            <ul className="timeline-content">
              <li>Collaborated with Professor Zhang to evaluate advanced large language models, including ChatGPT, Claude3, Gemini, and LLama3.</li>
              <li>Architected a full-stack system using React and Flask, optimizing data processing and storage on AWS and Oracle databases.</li>
              <li>Developed an automated script to process over 1000 questions through multiple language models, storing answers in a structured database.</li>
              <li>Designed a frontend history interface for viewing and comparing model responses, enhancing expert evaluations using MySQL for backend data management.</li>
              <li>Integrated MathJax, KaTex and Markdown for rendering complex mathematical formulas, improving data clarity and visual appeal.</li>
              <li>Deployed the frontend and backend on AWS servers using Node.js for the frontend and Gunicorn for the backend, ensuring robust and scalable performance.</li>
            </ul>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="June 2023 - August 2023"
            iconStyle={{ background: 'linear-gradient(135deg, #2f3640, #3e497a)', color: '#ffffff' }}
            icon={<FaBriefcase />}
          >
            <h3 className="vertical-timeline-element-title">Software Engineering Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">Shanghai MINGYAO Tech Co., LTD</h4>
            <p className="timeline-location">Shanghai, China</p>
            <ul className="timeline-content">
              <li>Engineered a Java Spring Boot backend, enhancing the customer management system’s efficiency by 40%.</li>
              <li>Spearheaded the development of three transformative Python tools, significantly elevating operational capabilities.</li>
              <li>Generated and archived 10,000+ secure passwords and auto-renamed 5,000+ invoices, boosting processing efficiency by 30%.</li>
              <li>Launched a Python application to enhance data querying, achieving a 25% efficiency surge in data management.</li>
              <li>Optimized data processes using Python scripts and SQL queries, achieving a 35% improvement in database connectivity speed.</li>
            </ul>
          </VerticalTimelineElement>


          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="January 2022 - December 2023"
            iconStyle={{ background: 'linear-gradient(135deg, #2f3640, #3e497a)', color: '#ffffff' }}
            icon={<FaBriefcase />}
          >
            <h3 className="vertical-timeline-element-title">An Introduction to Computer Science Courses Assistant</h3>
            <h4 className="vertical-timeline-element-subtitle">University of Illinois Urbana-Champaign</h4>
            <p className="timeline-location">Urbana, Illinois</p>
            <ul className="timeline-content">
              <li>Mentored and empowered over 120 students in Java programming, providing personalized guidance to strengthen their coding skills.</li>
              <li>Provided tailored assistance to students on homework problems and exam questions, both online and in-person, contributing to their improved understanding and performance in Java programming.</li>
            </ul>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="June 2022 - August 2022"
            iconStyle={{ background: 'linear-gradient(135deg, #2f3640, #3e497a)', color: '#ffffff' }}
            icon={<FaBriefcase />}
          >
            <h3 className="vertical-timeline-element-title">Information Technology Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">Fuyao Glass America</h4>
            <ul className="timeline-content">
              <li>Transformed employee data workflows using Google Automate, enhancing communication efficiency by 45%.</li>
              <li>Led a security presentation that reduced breaches by 30% among newcomers.</li>
              <li>Analyzed employee records using Excel for processing accounts and data for all employees facing dismissals or suspensions.</li>
              <li>Configured and repaired network equipment using Cisco in the factory.</li>
            </ul>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </section>
  );
}

export default ExperienceTimeline;
