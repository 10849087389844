import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Projects.css"; 

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Projects</strong>
        </h1>

        <div className="project-item">
          <h2>DUDU Secondary Market</h2>
          <p className="tech-stack">wxml, wxss, JavaScript, Json</p>
          <p>Dayton, OH</p>
          <ul>
            <li>Partnered with a team to build a DUDU secondary market platform to help students easily sell items or find products.</li>
            <li>Designed the main page of the platform, including categories for each product and subpages for the categories.</li>
            <li>Developed detailed filters within each category to facilitate easier navigation and product search.</li>
          </ul>
        </div>

        <div className="project-item">
          <h2>UIUC GPA Platform</h2>
          <p className="tech-stack">React, JavaScript, CSS, Flask, MySQL</p>
          <ul>
            <li>Created a data-driven web application using a dataset provided by Professor Wade Fagen-Ulmschneider to assist students in analyzing GPA trends across various courses and instructors at UIUC.</li>
            <li>Designed a searchable interface allowing students to query courses by source or professor, displaying historical class sizes and grade distributions through interactive charts and tables.</li>
            <li>Built a ‘Search Professor’ feature that lets users find all courses taught by a specific professor, aiding in academic planning and professor preference matching.</li>
            <li>Developed a GPA calculator according to UIUC’s grading standards, enabling students to input their courses, anticipated grades, and credit hours to estimate their overall GPA.</li>
          </ul>
        </div>

        <div className="project-item">
          <h2>Used Car Inquiry Platform</h2>
          <p className="tech-stack">React, JavaScript, CSS, Flask, MySQL, GCP</p>
          <ul>
            <li>Developed a comprehensive platform for querying and analyzing used car data, utilizing datasets from Kaggle and monthly sales data stored within a GCP database, supporting 10,000+ vehicle queries per month.</li>
            <li>Designed robust backend APIs using Flask for vehicle searches and user interactions, enhancing user decision-making by 25%.</li>
            <li>Incorporated a dynamic pricing tool and a feature for users to add cars to a favorite list, increasing user engagement by 30%.</li>
            <li>Collected and integrated data on state-specific tax rates, providing accurate cost estimates inclusive of state taxes.</li>
          </ul>
        </div>

        <div className="project-item">
          <h2>LeBron James Career Analysis</h2>
          <p className="tech-stack">Python, Pandas, Numpy, Matplotlib</p>
          <ul>
            <li>Conducted a comprehensive analysis of 21 years of LeBron James’ season-by-season performance data from 2003 to the present, focusing on predicting how his scoring changes with age.</li>
            <li>Managed data using Pandas and illustrated statistical data using Matplotlib, analyzing over 1,000 data points of LeBron’s scoring performance.</li>
            <li>Found that while his scoring remained consistent with age, there was an increase in assists and rebounds, showcasing his evolving role on the court with a prediction accuracy of 85%.</li>
          </ul>
        </div>

        <div className="project-item">
          <h2>Customer Management System</h2>
          <p className="tech-stack">Java, Spring Boot, MySQL</p>
          <ul>
            <li>Implemented the task management component, enabling supervisors to assign tasks and subordinates to accept and report task completion.</li>
            <li>Designed and revised task request API to optimize data handling and improve user interaction.</li>
            <li>Ensured only supervisors have the authority to delegate tasks, maintaining a clear hierarchical structure.</li>
            <li>Developed a real-time update feature for task acceptance and completion status, providing supervisors with comprehensive task progress oversight.</li>
          </ul>
        </div>

        <div className="project-item">
          <h2>Linear Algebra Python Calculator</h2>
          <p className="tech-stack">Python, NumPy, Jupyter Notebook</p>
          <ul>
            <li>Developed a linear algebra calculator using Jupyter Notebook to assist with solving and visualizing complex mathematical problems.</li>
            <li>Helped friends save time on homework and enhanced their understanding of various linear algebra operations.</li>
            <li>Implemented functions for matrix operations, eigenvalues, vector spaces, and more, utilizing Python and NumPy for efficient calculations.</li>
          </ul>
        </div>

      </Container>
    </Container>
  );
}

export default Projects;
